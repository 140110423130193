.react-flow__node {
}

.react-flow__handle {
  height: 12px;
  width: 12px;
}

.react-flow__handle-bottom {
  bottom: -6px;
}

.react-flow__handle-top {
  top: -6px;
}

.workflow-secondary-sidemenu {
  min-width: 20rem;
  max-width: 20rem;
}
