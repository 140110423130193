@tailwind base;
@tailwind components;
@tailwind utilities;

/* REACT GRIDS */
@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";
/* ANIMATIONS */

.pulse-red {
  transform: scale(1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.loader-global {
  width: 96px;
  height: 96px;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}
.loader-global::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  top: -48px;
  animation: animloader 4s ease infinite;
}
.loader-global::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  background: rgba(54, 98, 227, 0.85);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  animation: animloader2 2s ease infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 0 48px rgba(255, 255, 255, 0), 48px 48px rgba(255, 255, 255, 0),
      48px 96px rgba(255, 255, 255, 0), 0px 96px rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 0 48px rgba(54, 98, 227, 0.85), 48px 48px rgba(255, 255, 255, 0),
      48px 96px rgba(255, 255, 255, 0), 0px 96px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0 48px rgba(54, 98, 227, 0.85),
      48px 48px rgba(54, 98, 227, 0.85), 48px 96px rgba(255, 255, 255, 0),
      0px 96px rgba(255, 255, 255, 0);
  }
  37% {
    box-shadow: 0 48px rgba(54, 98, 227, 0.85),
      48px 48px rgba(54, 98, 227, 0.85), 48px 96px rgba(54, 98, 227, 0.85),
      0px 96px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 48px rgba(54, 98, 227, 0.85),
      48px 48px rgba(54, 98, 227, 0.85), 48px 96px rgba(54, 98, 227, 0.85),
      0px 96px rgba(54, 98, 227, 0.85);
  }
  62% {
    box-shadow: 0 48px rgba(255, 255, 255, 0), 48px 48px rgba(54, 98, 227, 0.85),
      48px 96px rgba(54, 98, 227, 0.85), 0px 96px rgba(54, 98, 227, 0.85);
  }
  75% {
    box-shadow: 0 48px rgba(255, 255, 255, 0), 48px 48px rgba(255, 255, 255, 0),
      48px 96px rgba(54, 98, 227, 0.85), 0px 96px rgba(54, 98, 227, 0.85);
  }
  87% {
    box-shadow: 0 48px rgba(255, 255, 255, 0), 48px 48px rgba(255, 255, 255, 0),
      48px 96px rgba(255, 255, 255, 0), 0px 96px rgba(54, 98, 227, 0.85);
  }
  100% {
    box-shadow: 0 48px rgba(255, 255, 255, 0), 48px 48px rgba(255, 255, 255, 0),
      48px 96px rgba(255, 255, 255, 0), 0px 96px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader2 {
  0% {
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25% {
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50% {
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100% {
    transform: translate(0, 0) rotateX(0) rotateY(360deg);
  }
}
